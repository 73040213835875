import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/dam/Hero';
import Company from '../sections/dam/Company';
import VideoExample from '../sections/sunseeker/video-example-sunseeker';
import Pricing from "../sections/dam/Pricing";
import WaveReverse from '../sections/common/WaveReverse';
import CommitVideo from '../sections/common/CommitVideo';
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/default.jpg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const IndexPage = ({ data }) => {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (prevPos.y > currPos.y) {
      setShowReveal(false);
    } else {
      setShowReveal(true);
    }
  });
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-left',
          headerFluid: false,
          version: 'home-version',
          footerStyle: 'digma',
        }}
      >
        <SEO
          title="Digital Asset Management"
          description="Harness the power to effortlessly organize and share your visual assets, driving seamless collaboration and boosting your brand."
          image={metaImg}
        />
        <div style={{maxWidth: '1350px'}} className='container mt-25 mb-15 d-flex flex-column justify-content-center'>
          <h3 className='mb-5'>76 YACHT</h3>
          <VideoExample />
          <h4 className='mt-10'>Speed & quality</h4>
          <p>Engineered to support high-resolution videos for high volume traffic websites. Our focus is on enhancing viewer engagement and delivering unparalleled quality, irrespective of the viewing environment and connection speed.</p>
          <h4>Simple to integrate</h4>
          <p>Incorporating videos into your website is effortlessly achieved with Digma's compact embed codes. Our concise code snippets make embedding a breeze, ensuring that your videos load swiftly for an optimal user experience.</p>
          <h4>Adaptable and mobile-Optimized</h4>
          <p>Our responsive player ensures seamless video playback on mobile devices, dynamically adjusting to various screen sizes. Digma videos seamlessly play inline on mobile, directing attention to your content without causing distractions on your site. Whether your audience views content on desktop or mobile, all your customizations maintain consistency.</p>
        </div>
        
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <Pricing className="grey" />
        <div style={{ overflow: 'hidden', background: '#f7f9fc' }}>
          <CommitVideo />
        </div>
      </PageWrapper>
    </>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query {
    bgImg: file(relativePath: { eq: "home-1/png/desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bgImg2: file(relativePath: { eq: "home-6/png/hero-mobile-empty.png" }) {
      childImageSharp {
        fluid(maxWidth: 1240) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
